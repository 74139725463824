.oneview {
  position: relative;
  width: 1920px;
  height: 1080px;
  background: url(../../assets/images/oneview-bg.png) no-repeat;
  background-size: cover;
  user-select: none;
}

.oneview-top-logo {
  position: absolute;
  top: 43px;
  left: 150px;
  width: 450px;
  text-align: left;
}

.oneview-timer {
  position: absolute;
  top: 4px;
  right: 0px;
  font-family: 'KT font';
  font-weight: 700;
  font-size: 16px;
  opacity: .7;
  /* border: 1px solid #88A3CA6a; */
  /* padding: 4px 16px; */
  /* border-radius: 10px; */
}

/*
ai alarm detail - topology tab
*/
.oneview.topology-only {
  position: relative;
  width: 830px;
  height: 490px;
  background: rgba(0,24,87,1);
  background: linear-gradient(0deg, rgba(0,24,87,1) 0%, rgba(33,117,214,1) 48%, rgba(0,26,89,1) 100%);
  user-select: none;
  border-radius: 20px;
  margin: 16px 8px;
  padding: 0;
  /* -webkit-box-shadow: inset 0 0 20px 5px #36a4ee;
  -moz-box-shadow: inset 0 0 20px 5px #36a4ee;
  box-shadow: inset 0 0 20px 5px #36a4ee; */
  -webkit-box-shadow: inset 0 0 20px 5px #000;
  -moz-box-shadow: inset 0 0 20px 5px #000;
  box-shadow: inset 0 0 20px 5px #000;
  border: 1px solid #36a4ee;
}
.oneview.topology-only .oneview-content-main {
  position: absolute;
  top: 0;
  left: 0;
  width: 830px;
  height: 490px;
}
.oneview.topology-only .oneview-topleft-deco {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 115px;
  /* height: 200px; */
}
.oneview.topology-only .oneview-topleft-deco-br {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 115px;
  transform: rotate(180deg);
  /* height: 200px; */
}
.oneview.topology-only .oneview-bottomleft-deco {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 85px;
  /* height: 200px; */
}
.oneview.topology-only .oneview-bottomleft-deco-tr {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 85px;
  transform: rotate(180deg);
  /* height: 200px; */
}
/*
ai alarm detail - topology tab
*/

.oneview-background {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.oneview-background svg {
  position: absolute;
  top: 0;
  left: 0;
}

.oneview-topleft-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 660px;
  height: 70px;
  background: url(../../assets/images/oneview-topleft-frame.png) no-repeat;
  background-size: cover;
  -webkit-user-select: none;
  user-select: none;
}
.oneview-topleft-deco {
  position: absolute;
  top: 110px;
  left: 50px;
  width: 234.64px;
}
.oneview-bottomleft-deco {
  position: absolute;
  bottom: 240px;
  left: 45px;
  width: 150px;
}
.oneview-bottomleft2-deco {
  position: absolute;
  bottom: 50px;
  left: 50px;
  width: 173px;
}
.oneview-bottom-deco {
  position: absolute;
  bottom: 180px;
  left: 357px;
  width: 182.04px;
}

.oneview-title {
  position: absolute;
  top: 0;
  color: #fff;
}

.oneview-content {
  height: 100%;
}

.oneview-content-main {
  position: absolute;
  top: 31px;
  left: 31px;
  width: 1328px;
  height: 1018px;
}

.oneview-content-main canvas {
  border-radius: 30px;
}

.oneview-content-sidebar {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 545px;
  /* border: solid 1px #0ff; */
  /* border-top-left-radius: 28px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 28px;
  border-bottom-left-radius: 28px; */
}

.oneview-alarms {
  position: absolute;
  bottom: 212px;
  left: 390px;
  width: 1100px;
}

.oneview-hud {
  position: absolute;
  top: 40px;
  right: 590px;
  width: 443px;
  height: 74px;
  /* border: solid 1px #ffffff11; */
}
.oneview-hud-background {
  position: absolute;
  left: 0;
  bottom: -14px;
  width: 443px;
  height: 14px;
}
.oneview-hud-content {
  position: absolute;
  top: 1em;
  left: 2em;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: space-evenly;
  align-items: flex-start;
}
.oneview-hud-content-item {
  position: relative;
  flex: auto;
  color: #fff;
  line-height: 1.3em;
  font-weight: 700;
  font-size: 14px;
}

.oneview-hud-content-item::after {
  display: block;
  top: 0.5em;
  right: 1.7em;
  content: '';
  position: absolute;
  width: 1px;
  background-color: #88a3ca;
  height: 2.5em;
}
.oneview-hud-content-item:last-child::after {
  display: none;
}

.oneview-hud-content-item table {
  margin: 0.5em 0;
  /* text-align: right; */
}

.oneview-hud-content-item th {
  color: #88a3ca;
  font-weight: 900;
  text-align: left;
}

.oneview-hud-content-item td {
  padding-left: 0.5em;
}

.oneview-hud-ai {
  position: absolute;
  left: 70px;
  bottom: 75px;
  width: 126px;
}
